import { ErrorMessage } from '@hookform/error-message';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Header, Footer, Popup, Profile, ToolTip } from '../../components'
import { AppContext } from '../../contexts';
import { RegistrationModel } from '../../types';
import { useApiService, useContextService } from '../../services';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './registration.css'
import { CourseDetails } from '../../components'
import { PageUrl, TooltipMessaage } from '../../util/app-config';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { RegistrationEnquiryModel } from '../../types/registrationEnquiry';

function Registration()
{
    console.log("Registration Start - WOGAA");
    if (window.wogaaCustom) {
        window.wogaaCustom.startTransactionalService('fhd2hubsfa-5327');
    }

    const navigate = useNavigate();

    const appContext = useContext(AppContext);
    const registrationContext = appContext.registration;
    const imageContext = appContext.image;
    const internetUser = appContext.internetUser;
    const navigationContext = appContext.navigation;
    const { saveRegistration, updateRegistration, saveFile } = useApiService();
    const [isConfirmPopupVisible, setIsConfirmPopupVisible] = useState<boolean>(false)
    const [isPhotoRequired, setIsPhotoRequired] = useState<boolean>(false)
    const { putContextRegistration, patchContextRegistrationAttachment } = useContextService();

    const { register, setValue, reset, control, getValues, handleSubmit, formState: { errors } } = useForm<RegistrationModel>({ criteriaMode: "all" });
   

    const onClickSubmit = handleSubmit((registration: RegistrationModel) => {
        if (!imageContext.imageBase64) {
            setIsPhotoRequired(true)
        } else {
            setIsPhotoRequired(false)
            setIsConfirmPopupVisible(true)
        }
    });

    const submitRegistration = async () => {
        const regValues: RegistrationModel = getValues();
        if (registrationContext.id) {
            // set to context as as regValues not contains disabled field values
            registrationContext.mobile = regValues.mobile;
            registrationContext.email = regValues.email;
            await putContextRegistration(registrationContext)
            if (imageContext.imageBlob) {
                const file = await saveFile(imageContext.imageBlob, regValues.uinPassport)
                if (file !== null) {
                    await patchContextRegistrationAttachment(file);
                }
            }
            await updateRegistration(registrationContext);
            if (navigationContext.fromPage === PageUrl.EnquiryResult) {
                navigate(PageUrl.EnquiryResult)
            } else {
                navigate(PageUrl.Success)
            }
        }
        else {
            if (imageContext.imageBlob) {
                const file = await saveFile(imageContext.imageBlob, regValues.uinPassport)
                if (file !== null) {
                    await patchContextRegistrationAttachment(file);
                    regValues.attachment = registrationContext.attachment;
                    const registrationAfterSave = await saveRegistration(regValues);
                    if (registrationAfterSave) {
                        await putContextRegistration(registrationAfterSave)
                        if (navigationContext.fromPage === PageUrl.EnquiryResult) {
                            navigate(PageUrl.EnquiryResult)
                        } else {
                            navigate(PageUrl.Success)
                        }
                    }

                }
            }
        }

        console.log("Registration End - WOGAA");
        if (window.wogaaCustom) {
            window.wogaaCustom.completeTransactionalService('fhd2hubsfa-5327');
        }
    }

    const onClickTakeCamera = async () => {
        //const regValues: RegistrationModel = getValues();
        let regValues: RegistrationModel = getValues();
        if (registrationContext.id) {
            registrationContext.mobile = regValues.mobile;
            registrationContext.email = regValues.email;
            await putContextRegistration(registrationContext, true)
        } else {
            regValues = getValues();
            await putContextRegistration(regValues)
        }
        navigationContext.fromPage = PageUrl.Registration
        navigate(PageUrl.Camera)
    }

    useEffect(() => {

        // reset form with useregistration data
        if (navigationContext.fromPage === PageUrl.Success) {
            registrationContext.readonly = true;
            reset(registrationContext);
        } else {
            reset(registrationContext);
        }

        if (typeof window !== 'undefined') {
            // Add wogaa.js as Informal service
            const script1 = document.createElement("script")
            script1.src = "https://assets.wogaa.sg/scripts/wogaa.js";        
            script1.crossOrigin = 'anonymous';
            document.body.appendChild(script1)
        }

    }, [registrationContext]);

    const changeHandler = (e: any) => {
        e.target.value = e.target.value.toUpperCase();
    }

    return (
        <div className="gradient-box" >
            <Header></Header>
            <Profile isVisibleProfile={true}></Profile>
            <div className="half_bg-img">
                <div className="container">
                    <div className="white-box-curved enguiry-page">
                        <div className="enguiry-bx bg-trans">
                            {internetUser?.lastLogin && <span className="float-end">Last login:  {moment(internetUser.lastLogin).format('DD/MM/YYYY HH:mm')}</span>}
                            <h3><small>Hello!</small> {internetUser.name}</h3>
                        </div>
                        <div className="reg-form reg-box">
                            <h4>Profile details</h4>
                            <span>Your profile details will be safe with us</span>
                            <div className="row">
                                <div className="col-lg-10">
                                    <form>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Full Name (should be same name with UIN / Passport No.)<span className="star-red">*</span></label>
                                            <input type="text" className="form-control w-96" onKeyUp={changeHandler}
                                                {...register('name', { disabled: registrationContext.readonly, required: "Name is required" })} />
                                            <ErrorMessage errors={errors} name="name" render={({ message }) => <p>{message}</p>} />
                                            {registrationContext.id && <ToolTip id='namettip' message={TooltipMessaage.Name}></ToolTip>}
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">

                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Email </label>
                                                    <input type="name" className="form-control"
                                                        {...register('email',
                                                            {
                                                                pattern: {
                                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                                    message: "Email is invalid"
                                                                },
                                                            }
                                                        )} />
                                                    <ErrorMessage errors={errors} name="email" render={({ messages }) =>
                                                        messages &&
                                                        Object.entries(messages).map(([type, message]) => (
                                                            <p key={type}>{message}</p>
                                                        ))} />
                                                </div>

                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Date of Birth <span className="star-red">*</span></label>
                                                    <Controller
                                                        control={control}
                                                        {...register('dob', { disabled: registrationContext.readonly, required: 'Date of Birth is required' })}
                                                        name='dob'
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                maxDate={moment().toDate()}
                                                                disabled={registrationContext.readonly}
                                                                placeholderText='DD / MM / YYYY'
                                                                onChange={(date: any) => field.onChange(date)}
                                                                selected={field.value}
                                                                customInput={<MaskedInput className='form-control'
                                                                    mask={[/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                                    keepCharPositions={true}
                                                                    guide={true}
                                                                />}
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        )} />
                                                    <ErrorMessage errors={errors} name="dob" render={({ message }) => <p>{message}</p>} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Phone No. / Mobile No.  <span className="star-red">*</span></label>
                                                    <input type="name" className="form-control" maxLength={11}
                                                        {...register('mobile', {
                                                            required: "Phone number is required",
                                                            pattern: {
                                                                value: /^[0-9\b]+$/,
                                                                message: "Only Numbers or Digits"
                                                            },
                                                        })
                                                        } />
                                                    <ErrorMessage errors={errors} name="mobile" render={({ message }) => <p>{message}</p>} />
                                                    {registrationContext.id && <ToolTip id='contacttip' message={TooltipMessaage.Contact}></ToolTip>}
                                                </div>
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">UIN  / Passport <span className="star-red">*</span></label>
                                                    <input type="name" className="form-control" onKeyUp={changeHandler} maxLength={10}
                                                        {...register('uinPassport', { disabled: registrationContext.readonly, required: "UIN / Passport is required", maxLength: { value: 10, message: 'uin / passport exceed maxLength' } })} />
                                                    <ErrorMessage errors={errors} name="uinPassport" render={({ message }) => <p>{message}</p>} />
                                                    {registrationContext.id && <ToolTip id='uinttip' message={TooltipMessaage.UIN}></ToolTip>}
                                                </div>
                                            </div>
                                            {registrationContext.id && <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Status <span className="star-red">*</span></label>
                                                    <input type="name" className="form-control" onKeyUp={changeHandler}
                                                        {...register('statusText', { disabled: registrationContext.readonly })} />
                                                </div>
                                                <div className="mb-3"></div>
                                            </div>}
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-2">
                                    <div className="photo-bg">
                                        <label htmlFor="exampleFormControlInput1" className="form-label">Photo</label>
                                        {!imageContext.imageBase64 ?
                                            <img src="/ClientApp/build/Images/profile_default.png" alt="" className="img-fluid" />
                                            :
                                            <img src={imageContext.imageBase64} width={140} height={140} alt="" className="img-fluid" />
                                        }
                                        {/* {!registrationContext.readonly && <button className="secondary-btn mt-3 me-0" onClick={onClickTakeCamera}>Take Photo</button>} */}
                                        <button className="secondary-btn mt-3 me-0" onClick={onClickTakeCamera}>{registrationContext.id ? 'Retake' : 'Take'} Photo</button>
                                        {isPhotoRequired && <p className='errorMsg'>Photo required</p>}
                                    </div>
                                </div>
                            </div>
                            {(registrationContext.course360s && registrationContext.course360s.length > 0) && <CourseDetails course360s={registrationContext.course360s} />}
                            <div className="text-center">
                                <button type='button' onClick={onClickSubmit} className="primary-btn">{registrationContext.id ? 'Update' : 'Submit'}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer></Footer>
            <Popup
                bodyText='Are you sure all the information are correct?'
                buttonCancelText='Cancel'
                buttonOkText='Yes Proceed'
                isVisible={isConfirmPopupVisible}
                onClickCancel={() => setIsConfirmPopupVisible(false)}
                onClickOk={submitRegistration}
            >
            </Popup>
        </div>
    );
}

export default Registration;