import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Registraton from './pages/registration/registration'
import routes from './app-routes'
import Camera from './pages/camera/camera';
import Enquiry from './pages/enquiry/enquiry';
import Success from './pages/message/success';
import Savephoto from './pages/savephoto/savephoto'
import EquiryResult from './pages/enquiry/enquiry-result'
import EquiryMeatResult from './pages/enquiry/enquiry-meat-result'
import EquiryNotFound from './pages/enquiry/enquiry-not-found'
import EquiryMeatNotFound from './pages/enquiry/enquiry-meat-not-found'
import SPLogin from './pages/login/singpass-login'
import Company from './pages/company/company'
import { AppContext, defaultAppContextData } from './contexts/appdata'
import { PageUrl } from './util/app-config';
import AccessDenied from './pages/login/access-denied';
import SPAuthCallback from './pages/login/sp-callback-fh';
import Login from './pages/home/login';
import CorppassLogin from './pages/login/corppass-login'
import AuthCallbackCP from './pages/login/cp-callback';
import MyInfoLogin from './pages/login/myinfo-login';
import MIAuthCallback from './pages/login/mi-callback';
import TempLogin from "./pages/login/temp-login";
import { Helmet } from 'react-helmet';

declare global {
    interface Window {
        wogaaCustom: any;
    }
}


function App() {

    const APP_ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT ?? '';

    return (
        <AppContext.Provider value={defaultAppContextData}>

            <Helmet>
              
                <script src="https://assets.wogaa.sg/scripts/wogaa.js"></script>
            </Helmet>
            
            
            <BrowserRouter>
                <Routes>
                    <Route path={`/`} caseSensitive={false} element={APP_ENVIRONMENT == 'Dev' ? <TempLogin /> : <Login />} />
                    <Route path={`/${PageUrl.Login}`} caseSensitive={false} element={<Login />} />
                    <Route path={`/${PageUrl.Registration}`} caseSensitive={false} element={<Registraton />} />
                    <Route path={`/${PageUrl.Camera}`} caseSensitive={false} element={<Camera />} />
                    <Route path={`/${PageUrl.Success}`} caseSensitive={false} element={<Success />} />
                    <Route path={`/${PageUrl.Savephoto}`} caseSensitive={false} element={<Savephoto />} />
                    <Route path={`/${PageUrl.EnquiryResult}`} caseSensitive={false} element={<EquiryResult />} />
                    <Route path={`/${PageUrl.EnquiryMeatResult}`} caseSensitive={false} element={<EquiryMeatResult />} />
                    <Route path={`/${PageUrl.EnquiryNotFound}`} caseSensitive={false} element={<EquiryNotFound />} />
                    <Route path={`/${PageUrl.EnquiryMeatNotFound}`} caseSensitive={false} element={<EquiryMeatNotFound />} />
                    {/* <Route path={`/${PageUrl.TempLogin}`} caseSensitive={false} element={<TempLogin />} /> */}
                    <Route path={`/${PageUrl.Enquiry}`} caseSensitive={false} element={<Enquiry />} />
                    <Route path={`/${PageUrl.AccessDenied}`} caseSensitive={false} element={<AccessDenied />} />
                    <Route path={`/${PageUrl.Company}`} caseSensitive={false} element={<Company />} />
                    <Route path={`/${PageUrl.Company}`} caseSensitive={false} element={<Company />} />
                    <Route path={`/${PageUrl.SPLogin}`} caseSensitive={false} element={<SPLogin />} />
                    <Route path={`/${PageUrl.SPAuthCallbackFH}`} caseSensitive={false} element={<SPAuthCallback />} />
                    <Route path={`/${PageUrl.Login}`} caseSensitive={false} element={<Login />} />
                    <Route path={`/${PageUrl.CPLogin}`} caseSensitive={false} element={<CorppassLogin />} />
                    <Route path={`/${PageUrl.CPAuthCallback}`} caseSensitive={false} element={<AuthCallbackCP />} />
                    <Route path={`/${PageUrl.MILogin}`} caseSensitive={false} element={<MyInfoLogin />} />
                    <Route path={`/${PageUrl.MIAuthCallback}`} caseSensitive={false} element={<MIAuthCallback />} />
                </Routes>
            </BrowserRouter>
        </AppContext.Provider>
    );
}

export default App;
